
export const base_url = `${window.location.origin}/backend/`;
//  export const base_url = `http://localhost:2222/`;
// export const base_url = `http://185.209.75.6:2222/`;
// export const base_url = `https://outbookstest.tradestreet.in/backend/`;
// export const base_url = `https://outbooks.tradestreet.in/backend/`;
//  export const base_url = `${window.location.origin}/backend/`



 

 

 









